.googleButton {
  background: white;
  font-weight: 600;
  font-family: 'Roboto', -apple-system, 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  width: 300px;
  margin: 24px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 1px #eee;
  cursor: pointer;
}

.microsoftButton {
  background: rgb(47, 47, 47);
  color: white;
  font-weight: 600;
  font-family: 'Segoe UI', -apple-system, 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  width: 300px;
  margin: 24px;
  box-shadow: 0px 0px 10px 1px #eee;
  cursor: pointer;
}

.providerIcon {
  width: 40px;
  margin: 12px;
}

.buttonsWrapper {
  max-width: 700px;
  padding: 12px;
  margin: 0 auto;
}

.emailSignIn {
  width: 300px;
}

.subTitle {
  font-weight: 700;
  font-size: 1.2rem;
  margin: 0 auto;
  margin-bottom: 12px;
  margin-top: 24px;
  align-self: start;
  width: 300px;
}
