.loader {
  --loader-grey: #f3f3f3;
  border: 0.5em solid var(--loader-grey);
  border-radius: 50%;
  border-top: 0.5em solid var(--purple);
  width: 3em;
  height: 3em;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
