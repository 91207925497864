.wrapper {
  composes: wrapper from '../Add.module.css';
  width: 100%;
}

.loaderCenter {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.pill {
  background: white;
  border: 1px solid var(--purple);
  padding: 6px 12px;
  font-weight: 600;
  transition: all 0.2s;
  flex: 1;
  text-align: center;
  cursor: pointer;
}

.active {
  background: var(--purple);
  color: white;
}

.leftPill {
  composes: pill;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.rightPill {
  composes: pill;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.activeLeftPill {
  composes: leftPill;
  composes: active;
}

.activeRightPill {
  composes: rightPill;
  composes: active;
}

.code {
  font-family: monospace;
  padding: 2px 6px;
  background: #f0f0f0;
  margin: 0 3px;
  border-radius: 6px;
}

.memberName {
  font-weight: 600;
  font-size: 1.1rem;
}

.memberEmail {
  margin-top: 3px;
  font-size: 0.9rem;
}

.selectMemberRole {
  composes: btn from global;
  composes: action from global;
  border: 1px solid var(--purple);
  padding: 6px;
  font-size: 1rem;
  font-weight: 500;
  text-align-last: center;
  appearance: none;
  margin: 0 6px;
}

.colorLabel {
  border-radius: 6px;
  margin: 3px;
  padding: 6px 12px;
  border: 1px solid #ddd;
  font-weight: 600;
  font-size: 0.9rem;
}

.categoryElection {
  font-size: 1rem;
  font-weight: 600;
  padding: 3px 6px;
  margin: 0 12px;
}

.addedToSlack {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 12px;
}
