.wrapper {
  composes: wrapper from '../Add.module.css';
  width: 100%;
}

.loaderCenter {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
