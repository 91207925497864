.banner {
  background: var(--light-grey);
  box-shadow: 0px 5px 5px 0px rgba(80, 80, 80, 0.1);
  overflow: auto;
  position: sticky;
  top: 6rem;
  z-index: 1000;
}

@media (max-width: 520px) {
  .banner {
    top: 4.5rem;
  }
}

.colorBoxTitle {
  margin-left: 0.5rem;
  font-weight: 700;
  font-size: 1rem;
  color: var(--grey-label);
}

.categoryButtonSpacer {
  padding: 6px;
  margin: auto;
}

.categoryButton {
  padding: 3px 6px;
  white-space: nowrap;
}

.activeCategoryButton {
  composes: categoryButton;
  background: rgba(80, 80, 80, 0.1);
  border-radius: 6px;
}

.colorBox {
  height: 1rem;
  width: 1rem;
  border-radius: 6px;
  flex-shrink: 0;
  /* border: 1px solid #ddd; */
}

.width {
  width: 100%;
}

.nothingHere {
  font-weight: bold;
  font-size: 1.2rem;
  text-align: center;
  padding: 0 12px;
  max-width: 300px;
}

.nothingHereLite {
  composes: nothingHere;
  font-weight: 400;
}

.addDesktop {
  padding-top: 24px;
}

.addPlus {
  composes: visible-phone from global;
  background: linear-gradient(to right, #6e41dc, #8939d8);
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.ideaStateSelector {
  position: relative;
  background: var(--light-grey);
  overflow: auto;
  white-space: nowrap;
}

.ideaStateSpacer {
  margin: auto;
  margin-top: 6px;
  padding: 0 6px;
}

.flex {
  flex: 1;
}

.sort {
  composes: flex;
  padding: 0 24px;
}

.addCenter {
  composes: flex;
  text-align: center;
}

.sortMobile {
  composes: visible-phone from global;
  padding: 24px 24px 0 0;
}

.sortName {
  font-weight: 700;
  padding: 0 3px;
  color: var(--grey-label);
}

.ideasGrid {
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

@media (max-width: 360px) {
  .ideasGrid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}

.overlayIdea {
  composes: flex from global;
  composes: row from global;
  position: fixed;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.3);
  min-width: 100vw;
  z-index: 6000;
  overflow: auto;
  padding: 24px;
  -webkit-overflow-scrolling: touch;
}
