.toast {
  position: fixed;
  bottom: 0;
  padding: 24px;
  z-index: 1000000;
  width: 100%;
  transition: bottom 1s;
}

.openedToast {
  composes: toast;
}

.closedToast {
  composes: toast;
  bottom: -100vh;
}

.content {
  background: #444;
  margin: auto;
  flex: 1;
  max-width: 700px;
  /* min-height: 400px; */
  /* padding: 24px; */
  border-radius: 5px;
  color: var(--white);
  font-weight: 500;
}

.timeoutStripe {
  height: 10px;
  background: linear-gradient(to right, #6e41dc, #bd34f1);
  border-radius: 5px 5px 0 0;
  flex-basis: 100%;
}

.rightTimeoutStripe {
  background: grey;
  border-top-right-radius: 5px;
  flex: 1;
}
