html,
body,
#root {
  height: 100%;
}

img {
  max-width: 100%;
}

*:focus {
  outline: none;
}

.app {
  min-height: 100vh;
  min-height: stretch;
  background: var(--background);
  min-height: 100%;
}

.btn {
  border-radius: 6px;
  font-size: 1rem;
  padding: 12px;
  font-family: 'Montserrat';
  font-weight: bold;
  text-decoration: none;
  transition: background 0.2s;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  appearance: none;
  border: none;
}

.overflow {
  overflow: auto;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-no-shrink {
  flex-shrink: 0;
}

.row {
  flex-direction: row;
}

.column {
  flex-direction: column;
}

.grow {
  flex: 1;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: start;
}

.align-baseline {
  align-items: baseline;
}

.justify-center {
  justify-content: center;
}

.justify-space-around {
  justify-content: space-around;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

.justify-start {
  justify-content: flex-start;
}

.grid {
  display: grid;
}

.btn.small {
  width: 60px;
  height: 60px;
  box-shadow: var(--shadow);
  border-radius: 50%;
}

.btn.small i {
  font-size: 2rem;
}

.btn.active {
  background: linear-gradient(to right, #6e41dc, #8b2af9);
}

.btn.disabled {
  background: var(--grey-label);
  opacity: 1;
}

.btn.action {
  border: 1px solid #ddd;
  color: var(--text-black);
}

.btn.neutral {
  color: var(--white);
  background: linear-gradient(to right, rgb(90, 90, 90), rgb(59, 59, 59));
}

@media (max-width: 375px) {
  .hidden-phone {
    display: none;
  }
}

@media (min-width: 376px) {
  .visible-phone {
    display: none;
  }
}

.none {
  display: none;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

.nowrap {
  white-space: nowrap;
}

.pad {
  padding: 12px;
}

.pad-m {
  padding: 18px;
}

.pad-top {
  padding-top: 12px;
}
