.margin {
  margin: 0.5rem 0;
}

.input {
  composes: margin;
  border: 1px solid #eee;
  font-size: 1.2rem;
  font-family: inherit;
  font-weight: 500;
  color: #52546b;
  border-radius: 6px;
  padding: 6px;
}

.resizable {
  resize: vertical;
}

.notResizable {
  resize: none;
}

textarea.input {
  min-height: 50px;
}

.submit {
  composes: margin;
  composes: btn from global;
  composes: active from global;
}

.marginTop {
  margin-top: 1rem;
}

.label {
  display: block;
}

.label:not(:first-child) {
  margin-top: 1rem;
}

.labelText {
  font-size: 1rem;
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.color {
  appearance: none;
  width: 50px;
  height: 50px;
  border: 1px solid #ddd;
  border-radius: 6px;
  display: block;
  margin: 12px 0;
}

.color::-moz-color-swatch {
  display: none;
}

.color::-webkit-color-swatch-wrapper {
  display: none;
}

.color::-webkit-color-swatch {
  display: none;
}

.select:hover {
  transition-duration: 0.2s;
}

.select {
  padding: 0.1em 0.2em;
  border: none;
  background: none;
  color: white;
  border-radius: 6px;
  font-size: 1.1em;
  color: #aeb0ca;
  font-family: 'MontSerrat', sans-serif;
  font-weight: 700;
  box-shadow: 0 2px 15px #dbe2f0;
}

.option {
  font-size: 1.1em;
  color: #aeb0ca;
  font-family: 'MontSerrat', sans-serif;
  font-weight: 700;
}

.option:hover {
  background-color: #8b2af9;
}

.select:focus {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.checkbox {
  visibility: hidden;
  width: 0px;
  height: 0px;
  position: fixed;
  top: -100vh;
}

.checkboxDisplay {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 1px solid #ddd;
  border-radius: 6px;
}

label[data-small='true'] .checkboxDisplay {
  width: 20px;
  height: 20px;
}

.checkmarkStem {
  position: absolute;
  width: 4px;
  height: 21px;
  background-color: rgb(121, 214, 102);
  left: 20px;
  top: 8px;
  transform: rotate(45deg);
}

label[data-small='true'] .checkmarkStem {
  width: 2px;
  height: 11px;
  left: 10px;
  top: 4px;
}

.checkmarkKick {
  position: absolute;
  width: 11px;
  height: 4px;
  background-color: rgb(121, 214, 102);
  left: 7px;
  top: 19px;
  transform: rotate(45deg);
}

label[data-small='true'] .checkmarkKick {
  width: 6px;
  height: 2px;
  left: 3px;
  top: 10px;
}
