.wrapper {
  composes: wrapper from './Add.module.css';
  width: 100%;
}

.hiddenInput {
  visibility: hidden;
  position: fixed;
  top: -100vh;
  width: 0px;
  height: 0px;
}

.displayName {
  font-weight: 600;
}

.email {
  font-size: 0.8rem;
}

.loaderWrapper {
  width: 100px;
  height: 100px;
}

.organizationRow {
  padding: 0.5rem 0;
}

.orgPage {
  composes: btn from global;
  composes: active from global;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}
