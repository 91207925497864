.main {
  max-width: 1000px;
  margin: auto;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5rem;
}

@media (max-width: 768px) {
  .noColumns {
    grid-template-columns: auto;
  }

  .reverseCols {
    grid-row-start: 2;
  }
}

.section {
  padding: 3rem 3rem;
  composes: grid;
  composes: noColumns;
  composes: main;
}

@media (max-width: 768px) {
  .section {
    padding: 3rem 1rem;
  }
}

.first div h1 {
  color: #fafafa;
}

.second div h1 {
  background: linear-gradient(to left, #6e41dc, #bd34f1);
  background-clip: text;
  color: transparent;
}

.third div h1 {
  background: linear-gradient(to left, #f05d1b, #fdd13f);
  background-clip: text;
  color: transparent;
}

.violetGradientBackground {
  background: linear-gradient(to left top, #6e41dc, #8939d8);
}

.blackBackground {
  background-color: #2a2a2a;
}

.greyBackground {
  background-color: #fafafa;
}

.logo {
  color: #f0f0f0;
  font-weight: 700;
}

.paragraph {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #c7cee9;
}

.header {
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
}
