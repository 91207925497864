.body {
  padding: 0 2rem;
}

.interactions {
  font-weight: 700;
  font-size: 1.1rem;
}

.seeMore,
.seeMore:visited,
.seeMore:hover,
.seeMore:focus {
  font-size: 0.9rem;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.icon {
  font-size: 1.2rem;
  padding: 1rem;
  color: var(--text-black);
}

.icon:first-child {
  padding-left: 0;
}

.btn {
  composes: icon;
  cursor: pointer;
}

.date {
  color: #aeb0ca;
}

.count {
  margin-left: -0.5rem;
  margin-right: 0.5rem;
  color: #3c3c3c;
}

.count:last-child {
  margin-right: 0;
}

.archiveBtn {
  composes: btn;
  color: #cd7373;
}

.realizeBtn {
  composes: btn;
  color: #f0e681;
}

.sideStripe {
  height: 0.5rem;
}

.borderBottom {
  border-bottom: 1px solid #e3ecf3;
}

.code {
  border-radius: 6px;
  background: #ddd;
  padding: 3px 6px;
  font-weight: 500;
}

.title {
  margin: 0.5rem 0;
  font-size: 1.3rem;
  color: #676874;
}

.content {
  margin: 1.5rem 0;
  flex-grow: 1;
}

.contentText {
  font-weight: 500;
  font-size: 1.2rem;
  color: #a0a2bc;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  margin: 0;
}

.droppable {
  display: flex;
  flex-direction: column;
}

.dropped {
  border-top: 1px solid #e3ecf3;
}

.dropBtn {
  align-self: center;
  width: 100%;
  height: 1.2em;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
  transition-duration: 0.2s;
}

.dropBtn i {
  transform: scaleX(1.2);
  font-size: 1.3em;
  transition-duration: 0.2s;
}

.newCommentForm {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.commentsTitle {
  margin-top: 0.5em;
  margin-bottom: 0.1em;
  font-size: 1.1em;
  font-family: 'MontSerrat', sans-serif;
  font-weight: 600;
  color: #d0d0d0;
}

.newCommentInput {
  /* flex-grow: 1; */
  /* font-family: 'Montserrat', sans-serif; */
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 10px 10px 0 10px;
  margin: 0.4em 0.5em 0.4em 0;
  transition-duration: 0.2s;
  resize: none;
}

.newCommentInput:focus {
  background: #fafafa;
}

.newCommentSubmit {
  border-radius: 50%;
  background: #676874;
  flex-shrink: 0;
  height: 3rem;
  width: 3rem;
  transition: background 0.2s;
  color: #fafafa;
  font-size: 1.3rem;
}

.newCommentSubmitActive {
  composes: newCommentSubmit;
  background: linear-gradient(to right, #6e41dc, #8b2af9);
}

.commentContent {
  margin: 0.1em 0;
  display: inline-block;
  background: #f0f0f0;
  padding: 0.8em 1.2em;
  border-radius: 0px 10px 10px 10px;
}

.commentContentText {
  margin: 0;
  font-weight: 500;
  font-size: 0.9em;
}

.commentDate {
  color: #d0d0d0;
  font-size: 0.8rem;
  font-weight: 600;
  padding-bottom: 3px;
}

@media (max-width: 520px) {
  .title {
    font-size: 1.1rem;
  }

  .contentText {
    font-size: 1rem;
  }

  .content {
    margin: 0.75rem 0;
  }

  .interactions {
    font-size: 1rem;
  }

  .commentsTitle {
    font-size: 1rem;
  }

  .commentDate {
    font-size: 0.7rem;
  }

  .commentContentText {
    font-size: 0.8rem;
  }

  .newCommentInput {
    font-size: 1rem;
  }
}
