:root {
  --white: white;
  --background: #f7fafc;
  --purple: #8b2af9;
  --purple-rgb: 139, 42, 249;
  --light-grey: #f4f4f4;
  --grey-label: #aeb0ca;
  --text-black: #52546b;
}

:root {
  --shadow: 0 -5px 20px rgba(80, 80, 80, 0.4);
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

button {
  border: none;
  font-size: 1rem;
  appearance: none;
  background: inherit;
  color: inherit;
  font-family: inherit;
  margin: 0;
  padding: 0;
}

input[type='text'],
input[type='email'],
input[type='password'],
textarea {
  width: 100%;
  appearance: none;
}

a {
  text-decoration: none;
}
