.logo {
  height: 100px;
  width: 100px;
  margin: 24px;
}

.help {
  max-width: 400px;
  text-align: center;
}
