.callout {
  border-radius: 6px;
  padding: 12px;
  font-size: 0.8rem;
  border-left: 6px solid var(--purple);
  background: rgba(var(--purple-rgb), 0.05);
}

.callout[data-error] {
  border-left-color: rgb(255, 15, 15);
  background: rgba(255, 15, 15, 0.1);
}

.callout[data-info] {
  border-left-color: rgb(0, 191, 174);
  background: rgba(0, 191, 174, 0.1);
}

.callout[data-warning] {
  border-left-color: rgb(255, 217, 57);
  background: rgba(255, 217, 57, 0.1);
}

.callout[data-success] {
  border-left-color: rgb(20, 161, 7);
}
