.overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: var(--background);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity .2s;
  z-index: 1000;
}

:global(.overlay-transition-enter) {
  opacity: 0;
}

:global(.overlay-transition-enter-active) {
  opacity: 1;
}

:global(.overlay-transition-exit) {
  opacity: 1;
}

:global(.overlay-transition-exit-active) {
  opacity: 0;
}

:global(.overlay-transition-exit-done) {
  display: none;
}
