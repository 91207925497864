@import url('https://fonts.googleapis.com/css?family=Lora|Montserrat:400,500,600,700,800|Arvo:700|Crimson+Text:600|Roboto:400,900|Indie+Flower');

h1 {
  font-family: 'MontSerrat', sans-serif;
  font-size: 3em;
  font-weight: 800;
  margin: 0;
  white-space: normal;
}

/* Used in Add.js and Edit.js */
.add {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}

.add form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.add h1 {
  background: linear-gradient(to right, #6e41dc, #bd34f1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.add h2 {
  font-size: 2rem;
  font-weight: 500;
}

.add .add-idea-form textarea:hover,
.add .add-idea-form input:hover {
  transition-duration: 0.2s;
}
.add .add-idea-form textarea:focus,
.add .add-idea-form input:focus {
  outline: none;
  box-shadow: 0 2px 15px #dbe2f0;
}
.add .add-idea-form label {
  text-transform: uppercase;
  color: #aeb0ca;
  font-family: 'MontSerrat', sans-serif;
  font-weight: 700;
}
