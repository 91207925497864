.profilePicture {
  width: 50px;
  height: 50px;
  margin: 0 6px;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
}

.hugeProfilePicture {
  composes: profilePicture;
  width: 100px;
  height: 100px;
}
