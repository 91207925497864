.card {
  background: #ffffff;
  box-shadow: 0 2px 15px rgba(140, 170, 170, 0.4);
  border-radius: 6px;
  overflow: hidden;
  position: relative;
}

.paddedCard {
  composes: card;
  padding: 24px;
}
