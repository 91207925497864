.paddingX {
  padding: 0 24px;
}

.paddingTop {
  padding-top: 24px;
}

.paddingBottom {
  padding-bottom: 24px;
}

.logo {
  composes: flex from global;
  composes: align-center from global;
  margin: 0 0.5rem;
  color: var(--text-black);
  font-weight: 600;
  border: none;
  font-size: 1rem;
  padding: 0;
  cursor: pointer;
  background: transparent;
  border-bottom: 2px solid transparent;
  color: #8b2af9;
  font-weight: 700;
  font-size: 1.1rem;
  font-family: 'MontSerrat', sans-serif;
}

.logo img {
  padding-right: 12px;
}

.mainNav {
  display: flex;
  align-items: baseline;
  padding: 1.2rem 6px;
  align-items: center;
  background-color: #fcfcfc;
  box-shadow: 0px 5px 5px 0px rgba(80, 80, 80, 0.1);
  z-index: 5000;
  position: sticky;
  flex-shrink: 0;
  top: 0;
  height: 6rem;
}

.sidebar {
  position: absolute;
  right: 0;
  top: calc(1.2rem * 2 + 38px);
  margin: 3px;
  background: white;
  z-index: 10000;
  border-radius: 6px;
  border: 1px solid #ddd;
  max-height: calc(100vh - 5.5rem);
  overflow: hidden;
}

.inactive {
  composes: none from global;
}

.displayName {
  composes: paddingX;
  font-weight: 600;
}

.email {
  composes: paddingX;
  font-size: 0.8rem;
}

.border {
  border-bottom: 1px solid #ddd;
  width: 100%;
}

.checkboxOrg {
  margin-left: 12px;
}

.notificationIcon {
  font-size: 1.5rem;
  padding: 0 12px;
  cursor: pointer;
}

.notificationTray {
  composes: sidebar;
  right: 50px;
  max-width: 300px;
}

.notification {
  padding: 12px;
  border-bottom: 1px solid #ddd;
}

.readNotification {
  composes: notification;
  background: #ddd;
  opacity: 0.5;
  border-bottom-color: #ccc;
}

.notification:last-child {
  border-bottom: none;
}

.electionSection {
  text-align: center;
  text-transform: uppercase;
  font-size: 0.9rem;
}

.electionTitle {
  background: linear-gradient(to right, #6e41dc, #bd34f1);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 900;
  margin-bottom: 3px;
}

.electionCount {
  font-weight: 600;
  color: var(--text-black);
}

@media (max-width: 520px) {
  .mainNav {
    padding: 0.6rem 6px;
    height: 4.5rem;
  }

  .electionTitle {
    font-size: 0.8rem;
  }

  .electionSection {
    font-size: 0.7rem;
  }
}
