@import url(https://fonts.googleapis.com/css?family=Lora|Montserrat:400,500,600,700,800|Arvo:700|Crimson+Text:600|Roboto:400,900|Indie+Flower);
.Toast_toast__2Hubc {
  position: fixed;
  bottom: 0;
  padding: 24px;
  z-index: 1000000;
  width: 100%;
  transition: bottom 1s;
}

.Toast_openedToast__1UOnG {
}

.Toast_closedToast__3LBTH {
  bottom: -100vh;
}

.Toast_content__3wnwv {
  background: #444;
  margin: auto;
  flex: 1 1;
  max-width: 700px;
  /* min-height: 400px; */
  /* padding: 24px; */
  border-radius: 5px;
  color: var(--white);
  font-weight: 500;
}

.Toast_timeoutStripe__1HRY0 {
  height: 10px;
  background: linear-gradient(to right, #6e41dc, #bd34f1);
  border-radius: 5px 5px 0 0;
  flex-basis: 100%;
}

.Toast_rightTimeoutStripe__2ZSRy {
  background: grey;
  border-top-right-radius: 5px;
  flex: 1 1;
}

.Card_card__1eE8R {
  background: #ffffff;
  box-shadow: 0 2px 15px rgba(140, 170, 170, 0.4);
  border-radius: 6px;
  overflow: hidden;
  position: relative;
}

.Card_paddedCard__2TN7v {
  padding: 24px;
}

.Input_margin__1xrTH {
  margin: 0.5rem 0;
}

.Input_input__3PNbO {
  border: 1px solid #eee;
  font-size: 1.2rem;
  font-family: inherit;
  font-weight: 500;
  color: #52546b;
  border-radius: 6px;
  padding: 6px;
}

.Input_resizable__UoMky {
  resize: vertical;
}

.Input_notResizable__3p6dy {
  resize: none;
}

textarea.Input_input__3PNbO {
  min-height: 50px;
}

.Input_submit__cDMSz {
}

.Input_marginTop__35MfD {
  margin-top: 1rem;
}

.Input_label__z-LP7 {
  display: block;
}

.Input_label__z-LP7:not(:first-child) {
  margin-top: 1rem;
}

.Input_labelText__3AcT_ {
  font-size: 1rem;
  font-weight: 600;
}

.Input_bold__EkjIH {
  font-weight: 700;
}

.Input_color__HQHdp {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  width: 50px;
  height: 50px;
  border: 1px solid #ddd;
  border-radius: 6px;
  display: block;
  margin: 12px 0;
}

.Input_color__HQHdp::-moz-color-swatch {
  display: none;
}

.Input_color__HQHdp::-webkit-color-swatch-wrapper {
  display: none;
}

.Input_color__HQHdp::-webkit-color-swatch {
  display: none;
}

.Input_select__1us1c:hover {
  transition-duration: 0.2s;
}

.Input_select__1us1c {
  padding: 0.1em 0.2em;
  border: none;
  background: none;
  color: white;
  border-radius: 6px;
  font-size: 1.1em;
  color: #aeb0ca;
  font-family: 'MontSerrat', sans-serif;
  font-weight: 700;
  box-shadow: 0 2px 15px #dbe2f0;
}

.Input_option__2_LtB {
  font-size: 1.1em;
  color: #aeb0ca;
  font-family: 'MontSerrat', sans-serif;
  font-weight: 700;
}

.Input_option__2_LtB:hover {
  background-color: #8b2af9;
}

.Input_select__1us1c:focus {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.Input_checkbox__3Xscd {
  visibility: hidden;
  width: 0px;
  height: 0px;
  position: fixed;
  top: -100vh;
}

.Input_checkboxDisplay__1S0-u {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 1px solid #ddd;
  border-radius: 6px;
}

label[data-small='true'] .Input_checkboxDisplay__1S0-u {
  width: 20px;
  height: 20px;
}

.Input_checkmarkStem__xdZgU {
  position: absolute;
  width: 4px;
  height: 21px;
  background-color: rgb(121, 214, 102);
  left: 20px;
  top: 8px;
  transform: rotate(45deg);
}

label[data-small='true'] .Input_checkmarkStem__xdZgU {
  width: 2px;
  height: 11px;
  left: 10px;
  top: 4px;
}

.Input_checkmarkKick__2QZu3 {
  position: absolute;
  width: 11px;
  height: 4px;
  background-color: rgb(121, 214, 102);
  left: 7px;
  top: 19px;
  transform: rotate(45deg);
}

label[data-small='true'] .Input_checkmarkKick__2QZu3 {
  width: 6px;
  height: 2px;
  left: 3px;
  top: 10px;
}

.Loader_loader__1j2Bs {
  --loader-grey: #f3f3f3;
  border: 0.5em solid var(--loader-grey);
  border-radius: 50%;
  border-top: 0.5em solid var(--purple);
  width: 3em;
  height: 3em;
  animation: Loader_spin__3E4o4 1s linear infinite;
}

@keyframes Loader_spin__3E4o4 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.IdeaCard_body__3buHZ {
  padding: 0 2rem;
}

.IdeaCard_interactions__rTyMo {
  font-weight: 700;
  font-size: 1.1rem;
}

.IdeaCard_seeMore__ynvlK,
.IdeaCard_seeMore__ynvlK:visited,
.IdeaCard_seeMore__ynvlK:hover,
.IdeaCard_seeMore__ynvlK:focus {
  font-size: 0.9rem;
  -webkit-background-clip: text;
          background-clip: text;
  -webkit-text-fill-color: transparent;
}

.IdeaCard_icon__3rXa7 {
  font-size: 1.2rem;
  padding: 1rem;
  color: var(--text-black);
}

.IdeaCard_icon__3rXa7:first-child {
  padding-left: 0;
}

.IdeaCard_btn__raPyK {
  cursor: pointer;
}

.IdeaCard_date__2eUk_ {
  color: #aeb0ca;
}

.IdeaCard_count__pKLm1 {
  margin-left: -0.5rem;
  margin-right: 0.5rem;
  color: #3c3c3c;
}

.IdeaCard_count__pKLm1:last-child {
  margin-right: 0;
}

.IdeaCard_archiveBtn__3n7ph {
  color: #cd7373;
}

.IdeaCard_realizeBtn__356WF {
  color: #f0e681;
}

.IdeaCard_sideStripe__2T_ZK {
  height: 0.5rem;
}

.IdeaCard_borderBottom__26jyH {
  border-bottom: 1px solid #e3ecf3;
}

.IdeaCard_code__MZUiU {
  border-radius: 6px;
  background: #ddd;
  padding: 3px 6px;
  font-weight: 500;
}

.IdeaCard_title__PlfCx {
  margin: 0.5rem 0;
  font-size: 1.3rem;
  color: #676874;
}

.IdeaCard_content__3P42O {
  margin: 1.5rem 0;
  flex-grow: 1;
}

.IdeaCard_contentText__2xovQ {
  font-weight: 500;
  font-size: 1.2rem;
  color: #a0a2bc;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  margin: 0;
}

.IdeaCard_droppable__9fpvd {
  display: flex;
  flex-direction: column;
}

.IdeaCard_dropped__2qAov {
  border-top: 1px solid #e3ecf3;
}

.IdeaCard_dropBtn__3ktSw {
  align-self: center;
  width: 100%;
  height: 1.2em;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
  transition-duration: 0.2s;
}

.IdeaCard_dropBtn__3ktSw i {
  transform: scaleX(1.2);
  font-size: 1.3em;
  transition-duration: 0.2s;
}

.IdeaCard_newCommentForm__2XVcG {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.IdeaCard_commentsTitle__3HZDE {
  margin-top: 0.5em;
  margin-bottom: 0.1em;
  font-size: 1.1em;
  font-family: 'MontSerrat', sans-serif;
  font-weight: 600;
  color: #d0d0d0;
}

.IdeaCard_newCommentInput__3k22Q {
  /* flex-grow: 1; */
  /* font-family: 'Montserrat', sans-serif; */
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 10px 10px 0 10px;
  margin: 0.4em 0.5em 0.4em 0;
  transition-duration: 0.2s;
  resize: none;
}

.IdeaCard_newCommentInput__3k22Q:focus {
  background: #fafafa;
}

.IdeaCard_newCommentSubmit__zRTcJ {
  border-radius: 50%;
  background: #676874;
  flex-shrink: 0;
  height: 3rem;
  width: 3rem;
  transition: background 0.2s;
  color: #fafafa;
  font-size: 1.3rem;
}

.IdeaCard_newCommentSubmitActive__2KWh3 {
  background: linear-gradient(to right, #6e41dc, #8b2af9);
}

.IdeaCard_commentContent__i1jOE {
  margin: 0.1em 0;
  display: inline-block;
  background: #f0f0f0;
  padding: 0.8em 1.2em;
  border-radius: 0px 10px 10px 10px;
}

.IdeaCard_commentContentText__1VbuU {
  margin: 0;
  font-weight: 500;
  font-size: 0.9em;
}

.IdeaCard_commentDate__20GeT {
  color: #d0d0d0;
  font-size: 0.8rem;
  font-weight: 600;
  padding-bottom: 3px;
}

@media (max-width: 520px) {
  .IdeaCard_title__PlfCx {
    font-size: 1.1rem;
  }

  .IdeaCard_contentText__2xovQ {
    font-size: 1rem;
  }

  .IdeaCard_content__3P42O {
    margin: 0.75rem 0;
  }

  .IdeaCard_interactions__rTyMo {
    font-size: 1rem;
  }

  .IdeaCard_commentsTitle__3HZDE {
    font-size: 1rem;
  }

  .IdeaCard_commentDate__20GeT {
    font-size: 0.7rem;
  }

  .IdeaCard_commentContentText__1VbuU {
    font-size: 0.8rem;
  }

  .IdeaCard_newCommentInput__3k22Q {
    font-size: 1rem;
  }
}

.Popup_popup__3TSql {
  position: fixed;
  background: rgba(0, 0, 0, 0.1);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100000;
}

.Home_main__2zFWY {
  max-width: 1000px;
  margin: auto;
}

.Home_grid__gcfNF {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5rem;
}

@media (max-width: 768px) {
  .Home_noColumns__1r6Vf {
    grid-template-columns: auto;
  }

  .Home_reverseCols__3PloC {
    grid-row-start: 2;
  }
}

.Home_section__3EdEG {
  padding: 3rem 3rem;
}

@media (max-width: 768px) {
  .Home_section__3EdEG {
    padding: 3rem 1rem;
  }
}

.Home_first__3IU-S div h1 {
  color: #fafafa;
}

.Home_second__26AvH div h1 {
  background: linear-gradient(to left, #6e41dc, #bd34f1);
  -webkit-background-clip: text;
          background-clip: text;
  color: transparent;
}

.Home_third__3DR3m div h1 {
  background: linear-gradient(to left, #f05d1b, #fdd13f);
  -webkit-background-clip: text;
          background-clip: text;
  color: transparent;
}

.Home_violetGradientBackground__RIJfA {
  background: linear-gradient(to left top, #6e41dc, #8939d8);
}

.Home_blackBackground__1kNKA {
  background-color: #2a2a2a;
}

.Home_greyBackground__3R6MF {
  background-color: #fafafa;
}

.Home_logo__2sirm {
  color: #f0f0f0;
  font-weight: 700;
}

.Home_paragraph__3X1I8 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #c7cee9;
}

.Home_header__3pqFA {
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
}

.NotLoggedIn_main__1DIlM {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.NotLoggedIn_title__oFD2y {
  padding: 0 1rem;
  text-align: center;
}

.NotLoggedIn_clickHere__3uj8L {
  position: absolute;
  top: 80px;
  right: 50px;
  display: flex;
  align-items: center;
}

.NotLoggedIn_clickHereText__1qfgP {
  max-width: 90px;
  line-height: 1.5;
  font-weight: bold;
  font-family: 'Indie Flower';
  text-align: center;
  margin: 0 12px;
}

.Ideas_banner__7uR5y {
  background: var(--light-grey);
  box-shadow: 0px 5px 5px 0px rgba(80, 80, 80, 0.1);
  overflow: auto;
  position: -webkit-sticky;
  position: sticky;
  top: 6rem;
  z-index: 1000;
}

@media (max-width: 520px) {
  .Ideas_banner__7uR5y {
    top: 4.5rem;
  }
}

.Ideas_colorBoxTitle__2sFeU {
  margin-left: 0.5rem;
  font-weight: 700;
  font-size: 1rem;
  color: var(--grey-label);
}

.Ideas_categoryButtonSpacer__rMn9i {
  padding: 6px;
  margin: auto;
}

.Ideas_categoryButton__1grmo {
  padding: 3px 6px;
  white-space: nowrap;
}

.Ideas_activeCategoryButton__3WNUG {
  background: rgba(80, 80, 80, 0.1);
  border-radius: 6px;
}

.Ideas_colorBox__1G8o8 {
  height: 1rem;
  width: 1rem;
  border-radius: 6px;
  flex-shrink: 0;
  /* border: 1px solid #ddd; */
}

.Ideas_width__2PK0v {
  width: 100%;
}

.Ideas_nothingHere__1Djll {
  font-weight: bold;
  font-size: 1.2rem;
  text-align: center;
  padding: 0 12px;
  max-width: 300px;
}

.Ideas_nothingHereLite__3rYFF {
  font-weight: 400;
}

.Ideas_addDesktop__lantz {
  padding-top: 24px;
}

.Ideas_addPlus__1VzHR {
  background: linear-gradient(to right, #6e41dc, #8939d8);
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.Ideas_ideaStateSelector__1Nz8U {
  position: relative;
  background: var(--light-grey);
  overflow: auto;
  white-space: nowrap;
}

.Ideas_ideaStateSpacer__PjQyq {
  margin: auto;
  margin-top: 6px;
  padding: 0 6px;
}

.Ideas_flex__LaUYj {
  flex: 1 1;
}

.Ideas_sort__2mrwl {
  padding: 0 24px;
}

.Ideas_addCenter__2gvBo {
  text-align: center;
}

.Ideas_sortMobile__2XPRR {
  padding: 24px 24px 0 0;
}

.Ideas_sortName__1ComN {
  font-weight: 700;
  padding: 0 3px;
  color: var(--grey-label);
}

.Ideas_ideasGrid__mqm1W {
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

@media (max-width: 360px) {
  .Ideas_ideasGrid__mqm1W {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}

.Ideas_overlayIdea__uOyxH {
  position: fixed;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.3);
  min-width: 100vw;
  z-index: 6000;
  overflow: auto;
  padding: 24px;
  -webkit-overflow-scrolling: touch;
}

.Add_wrapper__uFjlr {
  max-width: 700px;
  padding: 24px;
  position: relative;
}

.Add_returnButton__3w7Ml {
  font-size: 1.25rem;
  font-weight: 600;
  padding-bottom: 24px;
  color: var(--grey-label);
  padding: 0;
  position: absolute;
  top: -12px;
  cursor: pointer;
}

@media (max-width: 375px) {
  .Add_returnButton__3w7Ml {
    padding-bottom: 12px;
    position: static;
  }
}

.Add_returnButton__3w7Ml i {
  padding-right: 12px;
}

.Edit_wrapper__8zT2F {
  max-width: 700px;
  padding: 24px;
  position: relative;
}

.Edit_returnButton__Qf4Xq {
  font-size: 1.25rem;
  font-weight: 600;
  padding-bottom: 24px;
  color: var(--grey-label);
  padding: 0;
  position: absolute;
  top: -12px;
  cursor: pointer;
}

@media (max-width: 375px) {
  .Edit_returnButton__Qf4Xq {
    padding-bottom: 12px;
    position: static;
  }
}

.Edit_returnButton__Qf4Xq i {
  padding-right: 12px;
}

.Main_title__PONBW {
  background: linear-gradient(to right, #6e41dc, #bd34f1);
  -webkit-background-clip: text;
          background-clip: text;
  -webkit-text-fill-color: transparent;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ProfilePicture_profilePicture__3yhfP {
  width: 50px;
  height: 50px;
  margin: 0 6px;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
}

.ProfilePicture_hugeProfilePicture__2dFWo {
  width: 100px;
  height: 100px;
}

.Callout_callout__27XYH {
  border-radius: 6px;
  padding: 12px;
  font-size: 0.8rem;
  border-left: 6px solid var(--purple);
  background: rgba(var(--purple-rgb), 0.05);
}

.Callout_callout__27XYH[data-error] {
  border-left-color: rgb(255, 15, 15);
  background: rgba(255, 15, 15, 0.1);
}

.Callout_callout__27XYH[data-info] {
  border-left-color: rgb(0, 191, 174);
  background: rgba(0, 191, 174, 0.1);
}

.Callout_callout__27XYH[data-warning] {
  border-left-color: rgb(255, 217, 57);
  background: rgba(255, 217, 57, 0.1);
}

.Callout_callout__27XYH[data-success] {
  border-left-color: rgb(20, 161, 7);
}

.Profile_wrapper__TVK7G {
  width: 100%;
}

.Profile_hiddenInput__3p0bX {
  visibility: hidden;
  position: fixed;
  top: -100vh;
  width: 0px;
  height: 0px;
}

.Profile_displayName__1yOm4 {
  font-weight: 600;
}

.Profile_email__HVCyq {
  font-size: 0.8rem;
}

.Profile_loaderWrapper__2YydL {
  width: 100px;
  height: 100px;
}

.Profile_organizationRow__3jXsn {
  padding: 0.5rem 0;
}

.Profile_orgPage__1gjek {
  flex: 1 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.New_wrapper__2MHMr {
  width: 100%;
}

.New_loaderCenter__3sd6p {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.Index_wrapper__1rQPf {
  width: 100%;
}

.Index_loaderCenter__34Bou {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.Index_pill__1Qd8L {
  background: white;
  border: 1px solid var(--purple);
  padding: 6px 12px;
  font-weight: 600;
  transition: all 0.2s;
  flex: 1 1;
  text-align: center;
  cursor: pointer;
}

.Index_active__eicQM {
  background: var(--purple);
  color: white;
}

.Index_leftPill__3CgEZ {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.Index_rightPill__2Ks4l {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.Index_activeLeftPill__2OVu1 {
}

.Index_activeRightPill__2lxST {
}

.Index_code__2TECP {
  font-family: monospace;
  padding: 2px 6px;
  background: #f0f0f0;
  margin: 0 3px;
  border-radius: 6px;
}

.Index_memberName__3hFLx {
  font-weight: 600;
  font-size: 1.1rem;
}

.Index_memberEmail__3_d0L {
  margin-top: 3px;
  font-size: 0.9rem;
}

.Index_selectMemberRole__3EFnt {
  border: 1px solid var(--purple);
  padding: 6px;
  font-size: 1rem;
  font-weight: 500;
  text-align-last: center;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  margin: 0 6px;
}

.Index_colorLabel__26rEm {
  border-radius: 6px;
  margin: 3px;
  padding: 6px 12px;
  border: 1px solid #ddd;
  font-weight: 600;
  font-size: 0.9rem;
}

.Index_categoryElection__3PH9w {
  font-size: 1rem;
  font-weight: 600;
  padding: 3px 6px;
  margin: 0 12px;
}

.Index_addedToSlack__2vCkO {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 12px;
}

.Analytics_wrapper__9cudx {
  max-width: 700px;
  padding: 24px;
  position: relative;
  margin: 0 auto;
  width: 100%;
}

.Analytics_pieWrap__3dJhb {
  height: 500px;
}

.Analytics_minHeight__1Fud9 {
  min-height: 500px;
}

.Analytics_calendarWrap__3mU71 {
  height: 200px;
}

.Analytics_minCalendarHeight__3eQ8Q {
  min-height: 200px;
}

.Footer_footer__1w0lV {
  background: var(--light-grey);
  padding: 6px 12px;
  border-top: 1px solid rgba(80, 80, 80, 0.1);
  font-size: 0.7rem;
  font-weight: 500;
}

.Footer_smallPadding__15B0H {
  padding: 6px 12px;
}

.Footer_legalTermsLink__3Qv00 {
  color: var(--purple);
}

.Footer_alignRight__1tpsF {
  text-align: right;
}

.Authentication_googleButton__MMKTh {
  background: white;
  font-weight: 600;
  font-family: 'Roboto', -apple-system, 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  width: 300px;
  margin: 24px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 1px #eee;
  cursor: pointer;
}

.Authentication_microsoftButton__25try {
  background: rgb(47, 47, 47);
  color: white;
  font-weight: 600;
  font-family: 'Segoe UI', -apple-system, 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  width: 300px;
  margin: 24px;
  box-shadow: 0px 0px 10px 1px #eee;
  cursor: pointer;
}

.Authentication_providerIcon__1aVuO {
  width: 40px;
  margin: 12px;
}

.Authentication_buttonsWrapper__DJfis {
  max-width: 700px;
  padding: 12px;
  margin: 0 auto;
}

.Authentication_emailSignIn__20R5o {
  width: 300px;
}

.Authentication_subTitle__3uLh5 {
  font-weight: 700;
  font-size: 1.2rem;
  margin: 0 auto;
  margin-bottom: 12px;
  margin-top: 24px;
  align-self: start;
  width: 300px;
}

.MicrosoftTeams_logo__2kgB5 {
  height: 100px;
  width: 100px;
  margin: 24px;
}

.MicrosoftTeams_help__KJkrr {
  max-width: 400px;
  text-align: center;
}

.Navbar_paddingX__hfZ0t {
  padding: 0 24px;
}

.Navbar_paddingTop__2rovo {
  padding-top: 24px;
}

.Navbar_paddingBottom__Z42Eh {
  padding-bottom: 24px;
}

.Navbar_logo__1H3m4 {
  margin: 0 0.5rem;
  color: var(--text-black);
  font-weight: 600;
  border: none;
  font-size: 1rem;
  padding: 0;
  cursor: pointer;
  background: transparent;
  border-bottom: 2px solid transparent;
  color: #8b2af9;
  font-weight: 700;
  font-size: 1.1rem;
  font-family: 'MontSerrat', sans-serif;
}

.Navbar_logo__1H3m4 img {
  padding-right: 12px;
}

.Navbar_mainNav__1Uw-X {
  display: flex;
  align-items: baseline;
  padding: 1.2rem 6px;
  align-items: center;
  background-color: #fcfcfc;
  box-shadow: 0px 5px 5px 0px rgba(80, 80, 80, 0.1);
  z-index: 5000;
  position: -webkit-sticky;
  position: sticky;
  flex-shrink: 0;
  top: 0;
  height: 6rem;
}

.Navbar_sidebar__33qmQ {
  position: absolute;
  right: 0;
  top: calc(1.2rem * 2 + 38px);
  margin: 3px;
  background: white;
  z-index: 10000;
  border-radius: 6px;
  border: 1px solid #ddd;
  max-height: calc(100vh - 5.5rem);
  overflow: hidden;
}

.Navbar_inactive__3xdGg {
}

.Navbar_displayName__PUpfa {
  font-weight: 600;
}

.Navbar_email__2orya {
  font-size: 0.8rem;
}

.Navbar_border__2kVLf {
  border-bottom: 1px solid #ddd;
  width: 100%;
}

.Navbar_checkboxOrg__3Za6C {
  margin-left: 12px;
}

.Navbar_notificationIcon__320vy {
  font-size: 1.5rem;
  padding: 0 12px;
  cursor: pointer;
}

.Navbar_notificationTray__3vdYf {
  right: 50px;
  max-width: 300px;
}

.Navbar_notification__1ZY5i {
  padding: 12px;
  border-bottom: 1px solid #ddd;
}

.Navbar_readNotification__1nDeo {
  background: #ddd;
  opacity: 0.5;
  border-bottom-color: #ccc;
}

.Navbar_notification__1ZY5i:last-child {
  border-bottom: none;
}

.Navbar_electionSection__1bIh9 {
  text-align: center;
  text-transform: uppercase;
  font-size: 0.9rem;
}

.Navbar_electionTitle__GUVr4 {
  background: linear-gradient(to right, #6e41dc, #bd34f1);
  -webkit-background-clip: text;
          background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 900;
  margin-bottom: 3px;
}

.Navbar_electionCount__MeeS0 {
  font-weight: 600;
  color: var(--text-black);
}

@media (max-width: 520px) {
  .Navbar_mainNav__1Uw-X {
    padding: 0.6rem 6px;
    height: 4.5rem;
  }

  .Navbar_electionTitle__GUVr4 {
    font-size: 0.8rem;
  }

  .Navbar_electionSection__1bIh9 {
    font-size: 0.7rem;
  }
}

.Overlay_overlay__3DDtl {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: var(--background);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity .2s;
  z-index: 1000;
}

.overlay-transition-enter {
  opacity: 0;
}

.overlay-transition-enter-active {
  opacity: 1;
}

.overlay-transition-exit {
  opacity: 1;
}

.overlay-transition-exit-active {
  opacity: 0;
}

.overlay-transition-exit-done {
  display: none;
}

h1 {
  font-family: 'MontSerrat', sans-serif;
  font-size: 3em;
  font-weight: 800;
  margin: 0;
  white-space: normal;
}

/* Used in Add.js and Edit.js */
.add {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}

.add form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.add h1 {
  background: linear-gradient(to right, #6e41dc, #bd34f1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.add h2 {
  font-size: 2rem;
  font-weight: 500;
}

.add .add-idea-form textarea:hover,
.add .add-idea-form input:hover {
  transition-duration: 0.2s;
}
.add .add-idea-form textarea:focus,
.add .add-idea-form input:focus {
  outline: none;
  box-shadow: 0 2px 15px #dbe2f0;
}
.add .add-idea-form label {
  text-transform: uppercase;
  color: #aeb0ca;
  font-family: 'MontSerrat', sans-serif;
  font-weight: 700;
}

:root {
  --white: white;
  --background: #f7fafc;
  --purple: #8b2af9;
  --purple-rgb: 139, 42, 249;
  --light-grey: #f4f4f4;
  --grey-label: #aeb0ca;
  --text-black: #52546b;
}

:root {
  --shadow: 0 -5px 20px rgba(80, 80, 80, 0.4);
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

button {
  border: none;
  font-size: 1rem;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: inherit;
  color: inherit;
  font-family: inherit;
  margin: 0;
  padding: 0;
}

input[type='text'],
input[type='email'],
input[type='password'],
textarea {
  width: 100%;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

a {
  text-decoration: none;
}

html,
body,
#root {
  height: 100%;
}

img {
  max-width: 100%;
}

*:focus {
  outline: none;
}

.app {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  min-height: -moz-available;
  min-height: stretch;
  background: var(--background);
  min-height: 100%;
}

.btn {
  border-radius: 6px;
  font-size: 1rem;
  padding: 12px;
  font-family: 'Montserrat';
  font-weight: bold;
  text-decoration: none;
  transition: background 0.2s;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
}

.overflow {
  overflow: auto;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-no-shrink {
  flex-shrink: 0;
}

.row {
  flex-direction: row;
}

.column {
  flex-direction: column;
}

.grow {
  flex: 1 1;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: start;
}

.align-baseline {
  align-items: baseline;
}

.justify-center {
  justify-content: center;
}

.justify-space-around {
  justify-content: space-around;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

.justify-start {
  justify-content: flex-start;
}

.grid {
  display: grid;
}

.btn.small {
  width: 60px;
  height: 60px;
  box-shadow: var(--shadow);
  border-radius: 50%;
}

.btn.small i {
  font-size: 2rem;
}

.btn.active {
  background: linear-gradient(to right, #6e41dc, #8b2af9);
}

.btn.disabled {
  background: var(--grey-label);
  opacity: 1;
}

.btn.action {
  border: 1px solid #ddd;
  color: var(--text-black);
}

.btn.neutral {
  color: var(--white);
  background: linear-gradient(to right, rgb(90, 90, 90), rgb(59, 59, 59));
}

@media (max-width: 375px) {
  .hidden-phone {
    display: none;
  }
}

@media (min-width: 376px) {
  .visible-phone {
    display: none;
  }
}

.none {
  display: none;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

.nowrap {
  white-space: nowrap;
}

.pad {
  padding: 12px;
}

.pad-m {
  padding: 18px;
}

.pad-top {
  padding-top: 12px;
}

