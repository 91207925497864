.main {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  padding: 0 1rem;
  text-align: center;
}

.clickHere {
  position: absolute;
  top: 80px;
  right: 50px;
  display: flex;
  align-items: center;
}

.clickHereText {
  max-width: 90px;
  line-height: 1.5;
  font-weight: bold;
  font-family: 'Indie Flower';
  text-align: center;
  margin: 0 12px;
}
