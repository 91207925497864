.footer {
  background: var(--light-grey);
  padding: 6px 12px;
  border-top: 1px solid rgba(80, 80, 80, 0.1);
  font-size: 0.7rem;
  font-weight: 500;
}

.smallPadding {
  padding: 6px 12px;
}

.legalTermsLink {
  composes: smallPadding;
  color: var(--purple);
}

.alignRight {
  composes: smallPadding;
  text-align: right;
}
