.wrapper {
  max-width: 700px;
  padding: 24px;
  position: relative;
  margin: 0 auto;
  width: 100%;
}

.pieWrap {
  height: 500px;
}

.minHeight {
  min-height: 500px;
}

.calendarWrap {
  height: 200px;
}

.minCalendarHeight {
  min-height: 200px;
}
