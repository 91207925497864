.wrapper {
  max-width: 700px;
  padding: 24px;
  position: relative;
}

.returnButton {
  font-size: 1.25rem;
  font-weight: 600;
  padding-bottom: 24px;
  color: var(--grey-label);
  padding: 0;
  position: absolute;
  top: -12px;
  cursor: pointer;
}

@media (max-width: 375px) {
  .returnButton {
    padding-bottom: 12px;
    position: static;
  }
}

.returnButton i {
  padding-right: 12px;
}
